<template>
    <form is="json-form" :action="action" @success="saveSupplier" @error="onError" :values="values">
        <template slot-scope="form">
            <div slot="header"><h2 class="font-weight-normal">Add new supplier</h2></div>
            <div slot="default">
                <mercur-input data-test="supplierAddNameInput" v-model="form.data.supplierName" required autofocus>
                    Supplier name
                </mercur-input>

                <mercur-select data-test="supplierAddStatusSelect" v-model="form.data.supplierStatus" required>
                    <template slot="label">Status</template>
                    <option v-for="(status, key) in supplierStatusses" :value="status.value" :key="key">{{status.title}}</option>
                </mercur-select>

                <mercur-input data-test="supplierAddEmailInput" type="email" v-model="form.data.emailAddress" required autofocus>
                    Email
                </mercur-input>

                <mercur-input data-test="supplierAddWebsiteInput" v-model="form.data.websiteAddress">
                    Web address - optional
                </mercur-input>

                <pretty-select
                    placeholder="Fast Editor File Type"
                    :options="['PDF', 'PNG']"
                    label="Fast Editor File Type"
                    v-model="form.data.fastEditorFileType"
                ></pretty-select>

                <mercur-input v-model="form.data.sourceId" required type="number" @input="validateInteger">
                    SourceId
                    <template slot="note">
                        <span class="text-secondary">SourceId MUST match the ID of the supplier on Presta's database</span>
                    </template>
                </mercur-input>

                <p v-if="form.successMessage" v-text="form.successMessage"></p>
                <p v-if="form.errors.message" v-text="form.errors.message"></p>
            </div>

            <div slot="footer" class="text-right">
                <mercur-button data-test="supplierAddCloseButton" class="btn btn-raised text-uppercase" @click="closeAction" :disabled="form.loading">Close</mercur-button>
                <mercur-button data-test="supplierAddSaveButton" class="btn btn-raised text-uppercase" type="submit" :disabled="form.loading">Add supplier</mercur-button>
            </div>
            <div data-test="loading" class="loading-overlay" v-if="form.loading">
                <mercur-progress-bar indeterminate></mercur-progress-bar>
            </div>
        </template>
    </form>
</template>

<script>
import JsonForm from '@/components/utils/JsonForm'
import CONFIG from '@root/config'
import PrettySelect from '@/components/utils/PrettySelect'

export default {
    name: 'SupplierAdd',
    components: { JsonForm, PrettySelect },
    computed: {
        action () {
            return CONFIG.API.ROUTES.SUPPLIERS.ADD
        },
        supplierStatusses () {
            return CONFIG.STATUSSES.SUPPLIERS.SUPPLIER
        },
    },
    data () {
        return {
            values: {
                supplierStatus: 'ACTIVE',
                fastEditorFileType: 'PDF',
            },
        }
    },

    props: {
        closeAction: {

        },
        saveAction: {

        },
    },

    methods: {
        saveSupplier (data) {
            this.saveAction(data, this.values)
        },
        onError (error) {
            this.$root.$emit('notification:global', {
                type: 'error',
                errors: error,
            })
        },
        validateInteger () {
            this.values.sourceId = String(parseInt(this.values.sourceId))
        },
    },
}
</script>
